<template>
  <div id="home">
    <div v-if="isPc">
      <headers :logoUrl="logoUrl"></headers>
      <pc></pc>
      <footers></footers>
    </div>
    <div v-else>
      <mobile></mobile>
    </div>
  </div>
</template>

<script>

import headers from "@/components/header";
import footers from "@/components/footer";
import pc from "@/components/pc";
import mobile from "@/components/mobile";
export default {
  components: {
    headers,
    footers,
    pc,
    mobile,
  },
  data() {
    return {
      logoUrl: 1,
      isPc: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (window.innerWidth <= 425) {
        this.isPc = false;
      } else {
        this.isPc = true;
      }
      console.log("isPc ===", this.isPc);
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  .index-bg {
    background: url("../../assets/carousel.jpg") center no-repeat;
    background-size: auto 100%;
    background-color: #000;
    .public-wrap {
      height: 378px;
      justify-content: center;

      .text {
        color: #fff;
        text-align: left;
        margin-bottom: 50px;
        margin-right: 154px;
        h3 {
          font-weight: normal;
          font-size: 22px;
          margin-bottom: 10px;
          color: #eee;
        }
        h1 {
          font-size: 40px;
          margin-top: 10px;
        }
      }
      .download {
        z-index: 999;
        margin-top: 50px;
        margin-right: 40px;
        .image {
          width: 524px;
          height: auto;
        }
      }
    }
  }
  .centent {
    .module {
      .public-wrap {
        // background: url("../../assets/资讯.jpg") 40% center  no-repeat;
        // background-size: auto 82%;
        height: 390px;
        justify-content: center;
        .moduleImg {
          height: 100%;
          width: 50%;
          text-align: right;
          // img {
          //   width: 50%;
          // }
        }
        .text {
          width: 46%;
          padding-left: 50px;
          h1 {
            font-size: 40px;
          }
          h3 {
            color: #999;
            font-size: 22px;
            font-weight: normal;
          }
        }
      }
    }
    // .info .moduleImg{
    //   background: url("../../assets/info.jpg") center no-repeat;
    //   background-size: 100% auto;
    // }
    .match .text {
      padding-right: 50px;
    }
  }
  .public-wrap {
    width: 100%;
    height: 60px;
    // background: aqua;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
// @media screen and (max-width: 1024px){

// }
// 屏幕宽度小于430px
@media screen and (max-width: 1024px) {
  #home {
    .index-bg {
      .public-wrap {
        height: 200px;
        justify-content: space-evenly;
      }
    }
    .centent {
      .module {
        .public-wrap {
          // background: url("../../assets/资讯.jpg") 40% center  no-repeat;
          // background-size: auto 82%;
          height: auto;
          align-items: center;
          .moduleImg {
            height: auto;
            img {
              width: 100%;
            }
          }
          .text {
            width: 50%;
            padding: 0 10px;
            h1 {
              font-size: 22px;
            }
            h3 {
              font-weight: normal;
              color: #999;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>

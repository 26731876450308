<template>
  <div style="background: #fff">
    <main class="page-content-fluid">
      <div class="app-bg">
        <div class="page-content-app flex items-center flex-custom-1">
          <div class="app-block-text flex-1">
            <h2 class="text-2xl text-white font-normal mb-1">球客岛APP</h2>
            <h3 class="text-white">赛事快讯、专家点评，你的观赛必备神器！</h3>
            <div class="flex gap-10 mt-2">
              <button
                style="cursor: pointer"
                class="flex gap-1 justify-center btn btn-android"
                @click="btnDownload('az')"
              >
                <i class="icon-android"></i> Android版
              </button>
              <button
                style="cursor: pointer"
                class="flex gap-1 justify-center btn btn-ios"
                @click="btnDownload('ios')"
              >
                <i class="icon-ios"></i> iPhone版
              </button>
            </div>
          </div>
          <div
            class="app-custom-img-1 flex-1 position-relative mt-20 height-430"
          >
            <img src="../../assets/downloadAPP/app-img-1.png" alt="" />
          </div>
        </div>
      </div>
      <section class="py-301">
        <div class="page-content-app flex items-center">
          <div class="app-block-text flex-1 order-1 mt-3">
            <h2 class="text-xxl text-black">体育资讯</h2>
            <h3 class="text-xl text-gray-2 font-normal my-20">
              球客岛APP，一款专门为体育爱好者打造的足篮球资讯平台。无论你钟情于哪一项体育赛事，这里都能为你实时更新全球最新赛事消息、球员近况和详尽的球队数据。
            </h3>
          </div>
          <div class="app-block-img flex-1">
            <img src="../../assets/downloadAPP/app-img-2.png" alt="" />
          </div>
        </div>
      </section>
      <section class="py-301">
        <div class="page-content-article flex items-center">
          <div class="app-block-text flex-1 text-center mt-3">
            <h2 class="text-xxl text-black">赛程跟踪</h2>
            <h3 class="text-xl text-gray-2 font-normal my-20">
              世界杯、欧冠杯、亚冠杯、中超、英超、西甲、德甲、意甲、法甲、日职联、韩K联、澳超、NBA、CBA等全球300余种足球篮球赛事相关赛程、比分战报。实时比分，快人一步，抢先决策，精彩不停！
            </h3>
          </div>
          <div class="app-block-img flex-1">
            <img src="../../assets/downloadAPP/app-img-3.png" alt="" />
          </div>
        </div>
      </section>
      <section class="py-301">
        <div class="page-content-article flex items-center">
          <div class="app-block-text flex-1 order-1 mt-3">
            <h2 class="text-xxl text-black">比赛数据</h2>
            <h3 class="text-xl text-gray-2 font-normal my-20">
              与其他体育资讯平台不同的是，球客岛APP千名专家在线分析每场比赛战队战绩信息、比分信息、阵容信息等细节数据。呈现具有权威性的赛事深入剖析和优选建议，为您的决策过程提供有力支持。
            </h3>
          </div>
          <div class="app-block-img flex-1">
            <img src="../../assets/downloadAPP/app-img-4.png" alt="" />
          </div>
        </div>
      </section>
      <el-dialog
        :visible.sync="centerDialogVisible"
        title="扫描下方二维码下载APP"
        width="30%"
        destroy-on-close
        center
      >
        <div style="width: 152px;margin: 0 auto;">
            <QrCode :id="'QrCode'" :text="qrvalue"></QrCode>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">关 闭</el-button>
          </span>
        </template>
      </el-dialog>
    </main>
  </div>
</template>

<script>
import QrCode from './qrCode'
import util from "@/utils/utils";
import "@/assets/css/main.css";
export default {
    components: {
        QrCode
    },
  data() {
    return {
      centerDialogVisible: false,
      qrvalue: "",
    };
  },
  methods: {
    async btnDownload(type) {
      console.log(type);
      const res = await util.downloadApp(type);
      console.log("res === ", res);
      if (res.c == 200) {
        const href = res.d.find((item) => item.title == type).href;
        // console.log(href);
        // this.qrCodeMethods(href);
        this.centerDialogVisible = true
        // this.qrvalue = href
        this.qrvalue = 'https://h5.qiukedao.net/wxtg/qkd.html?id=' + 'sn30000' 
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #fff;
  min-width: 960px;
}
// element-plus
.qrcode {
  text-align: center;
}
::v-deep .el-dialog__header {
  margin-right: 0;
}
</style>

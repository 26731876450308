
import axios from 'axios';
const downloadApp = async (type) => {
    const id = "sn30000";
    var data = {
      d: { id }
  }
  const jsonData = JSON.stringify(data)
  try {
    const response = await axios.post('https://api.qiuxiangbiao.com/resource/app-do/list', jsonData, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });

    if (response.data.c === 200) {
      // 处理成功响应
      // console.log(response.data);
    }

    return response.data;
  } catch (error) {
    // 处理请求错误
    console.error(error);
    return null;
  }
  };
  export default {
    downloadApp,
  };
  
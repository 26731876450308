<template>
  <div class="download">
    <compNavBar></compNavBar>
    <van-swipe
      class="my-swipe"
      :show-indicators="false"
      indicator-color="white"
    >
      <van-swipe-item v-for="item in url">
        <img :src="item.img" alt="" srcset="" />
      </van-swipe-item>
    </van-swipe>
    <div class="crad">
      <img src="../../assets/logo2.png" alt="" />
      <div>球客岛APP-赛事专家随时为你服务</div>
      <div class="rate">
        <van-rate
          v-model="rateValue"
          :size="14"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          readonly
        />
        <!-- <van-rate
          v-model="rateValue"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          readonly 
        /> -->
        <span style="color: #999">5.0</span>
      </div>
      <div style="color: #999; font-size: 12px">带你看懂球类比赛方向</div>
      <div style="position: relative; flex: 1; margin: 16px">
        <div class="inviteBtn2 flash animated infinite" @click="clickDown">
          立即下载
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import compNavBar from "../compNavBar";
import util from "@/utils/utils";
function useAssets(path) {
  return require("@/assets/" + path);
}
export default {
  components: {
    compNavBar,
  },
  data() {
    return {
      rateValue: 5,
      url: [
        {
          img: useAssets("downloadAPP/download1.png"),
        },
        {
          img: useAssets("downloadAPP/download2.png"),
        },
        {
          img: useAssets("downloadAPP/download3.png"),
        },
        {
          img: useAssets("downloadAPP/download4.png"),
        },
        {
          img: useAssets("downloadAPP/download5.png"),
        },
      ],
    };
  },
  methods: {
    async clickDown() {
      const res = await util.downloadApp();
      console.log(res);
      const isAndroid = /Android/.test(navigator.userAgent);
      const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    //   console.log(isAndroid ? "Android" : isIOS ? "iOS" : "Unknown");
      if (isAndroid) {
        window.open(res.d.find((i) => i.title == "az").href);
      } else if (isIOS) {
        setTimeout(() => {
          window.location = res.d.find((i) => i.title == "ios").href;
        }, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  position: relative;

  img {
    width: 100%;
    display: block;
    height: calc(100vh - 44px);
  }
  // .my-swipe{
  //   height: calc( 100vh - 44px);
  // }
  .crad {
    position: fixed;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    height: 160px;
    bottom: 20px;
    width: 90%;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    img {
      width: 50px;
      height: 50px;
      display: block;
      margin: -26px auto 10px;
    }

    .inviteBtn2 {
      max-width: 450px;
      margin: 0 auto;
      position: absolute;
      line-height: 40px;
      height: 40px;

      bottom: 0;
      left: 0;
      right: 0;
      background: #e0402d;
      border-radius: 20px;
      color: #fff;
    }

    .rate {
      display: flex;
      align-items: center;
      font-size: 16px;
      height: 16px;
      justify-content: center;
      margin: 4px 0 8px;

      span {
        margin-left: 4px;
      }
    }
  }
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes flash {
  /*只用修改这里就可以实现不同动画。例如大小闪烁transform: scale(.9);*/
  0%,
  100%,
  50% {
    /* opacity: 1; */
    transform: scale(1);
  }

  25%,
  75% {
    /* opacity: 0.4; */
    transform: scale(0.9);
  }
}

@keyframes flash {
  0%,
  100%,
  50% {
    /* opacity: 1; */
    transform: scale(1);
  }

  25%,
  75% {
    /* opacity: 0.4; */
    transform: scale(0.9);
  }
}
</style>

<template>
  <footer class="footer shadow bg-action-black-85">
    <div class="page-content custom-footer py-30 text-white text-link-gray-1">
      <div class="flex justify-between">
        <div class="wangbei">
          广西知音文化传媒有限公司 Copyright©2023 广西知音<a
            title="球客岛"
            style="margin: 0 4px"
            >球客岛</a
          >All Rights Reserved
        </div>
        <div class="wangbei">
          <a :href="power">权限说明</a>
          |
          <a :href="serviceAgreement">服务协议</a>
          |
          <a :href="privacyAgreement">隐私政策</a>
        </div>
      </div>
      <div class="py-10">
        <div class="wangbei">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFKADAAQAAAABAAAAFgAAAADIHaOdAAAE/UlEQVQ4Ea2Uf2wTZRjHv3e9Xq/Xdr3+Xl0n2Q+BTRhThgqOIWgEDBs4EwLGqBhE0CCByB8a/iEYA//MiAYxE6MQYlRISLaFMOaMAotgDMSB2xhlbGvHfvTH2mvv2l7v6tsiy+BffXPv3T333vPJN+/3eV4ql8vh/xxMHpbFUIHJoIg8TUDmCgbPduKx9TuKpdtXm345+8fbaTnLPvfiolZ7Tc2ZvtPfB6oamwG2gvw/ifDNM4V8x9w9KAAL0YO3lSTc/u2O3U05eZAbTfPQ2QRIp/o/l4+2fVr/fHkbWf+GzPYH0wD64Q8k5rq/PNb9+raujYnUMDfXrMNWbgLbuVEsW+qBfamH2brt/Mudhw/moYaH8x8G+tJXD5z77GQMm7eW401fHNavbiA8oCLsVxFbdxTr1V7sOr4JracV5Aa+/ikvYDZ0NrBqpH2fv2Fdb4NQ6cF7zl4MnYyDLhWgihkgrYFyCejZeQkb+avwPPsEXljX0Th18dhtApx3H1oAJvv8YFLnzG0DFvbKWB3W1olIXCOwkRgowQBDREXq0gT0ZQ5wCRmh7y6jeXUG3beqceKC6jVTSaO97I0C875Cn/b7zy0aZQQYCUXiGOLjgF5VgEgKVAUPdrEA+m4SLDSEhmSU2NMARSGaoGFwMS2EVpYnFoDD/edfyQSD9TYzS2rIgJzXC2d9MRLIQVzmgLrZB/P+WvC7akC7eLgXO5GUyDaQGva6eSBwbWVktOetGSBvtlXFRQXlrhTZYgc6L/NgVhUh3DgHalAE9VcSUscElKFpsHvrYd+9CKeOR0m+CbULGST9k0gF/L4ZoBYZa4xHGSzwifA9xeLICRN+PRhA7RIvrLwF0o0otMk0Ur1RWILTuH7gJg4fN8BTK2BhSQyhYBpKpL9+BkgzSUGM0yiauomNq1VkFTOU9nE4fvgTdIkeGZcOmpuBc5UX3u7bCLVeRzKjQ3OTHpbwIMQ4A8ZC6/PAQqcY+Ue76Hl3mlRPCbY8LaKlxIUjwSooozfgIAm01QiZqBQr3QjavPiRJuYVCdjyzCSyFh+ExynwttKOGSDxxp2L5zDmF7Ggaggff7AY+/Y2oF1cCMQVNElh2AUapzosxChiAmzY8y6HJZWDGBskYUqClhUXzQAZIVOXtXigjCsITPbh/XoDmrtM6D4roZ+uwZrqW/AaQ8i2lSATlVE6n8dHa+5goucOJIUD52TBCOz8PJDKH1/pxJVXw5cPnZTHM0iHSO0FhlGxoRYGD+kqNUwuBolJGdYyOzDHQdZDGDozANnihLncDGMxD2vl5hWs7aXf7gOhowyvRa63fKGmZGvowjgYUw7FK6qgRmQoUhI0T5TYTaBYPcI9fmRVBta6CigphK3zGjaxer6LAO+Zkg5pRKwcTI0bZc6jWT2rKqHGkqSH4wDPQscKYIkPmekEkoEoOKKS83mQnpaQCoo5Y2kCGbK7dtu/naLDRZD5oftJS7GeuJiOadCIAk3VITUlko7QkAnHkBicAqUj1UFaLnU3BL2Rhme518ny4jtk5rfwnkKDY0n+fUN07MKhTODvLaxOMvEOA7JJ0l6UAl16Glk5A8t8J3ScDhk5AdrkBGUrHskyFa0c7/okD8iP2Se2ROKd0T7Pfjk2tTatjK54pNpVrYmUmRNoO2O0QLyLEaNLkBh3w7WIf7JLHo52zllOTotZo2DKrPg/v/4DOvcIyCr6AZkAAAAASUVORK5CYII="
            style="float: left; height: 20px"
          />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010002450167"
          >
            桂公网安备45010002450167号</a
          >
          <a style="margin-left: 14px" href="https://beian.miit.gov.cn"
            >备案号：桂ICP备2023005192号-4
          </a>
          <a style="margin-left: 14px"
            >增值电信业务经营许可证：桂B2-20230827
          </a>
          <a style="margin-left: 14px">桂网文 (2023) 3549-701号 </a>

          <a style="margin-left: 14px"
            >广播电视节目制作经营许可证：(桂南) 字第 00619号
          </a>
        </div>
      </div>
      <div class="wangbei">
        <span style="margin-right: 6px">联系电话：13221881101</span>
        地址：中国（广西）自由贸易试验区南宁片区盘歌路8号大唐国际中心1号楼五层519号办公室
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      power: "https://h5.qiukedao.net/power.html",
      serviceAgreement: "https://api.qdszq.com/html/serviceAgreement.html",
      privacyAgreement: "https://api.qdszq.com/html/privacyPolicy.html",
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  // position: absolute;
  bottom: 0;
  width: 100%;
  .wangbei {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped>
.NavBar {
  //   margin-bottom: 16px;
  height: 44px;
  display: block;
  border-bottom: 0px solid #eee;
  ::v-deep .van-nav-bar__content{
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  .van-button--round {
    width: 80px;
    height: 26px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
</style>

<template>
  <van-sticky>
    <van-nav-bar placeholder safe-area-inset-top class="NavBar" >
      <template #left>
          <img style="width: 95px" src="../../assets/logo.png" />
      </template>
      <template #right>
      </template>
    </van-nav-bar>
  </van-sticky>
</template>

<script>

export default{
    data(){
        return{

        }
    },
    mathods:{
        clickDown(){
//   util.downloadApp();
}
    }
}
</script>

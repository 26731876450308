import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
import { Sticky } from 'vant';
import { Rate } from 'vant';
import { NavBar } from 'vant';
import { Icon } from 'vant';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Rate);

Vue.use(Sticky);
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  el:'#app',
  router,
  render: h => h(App)
})

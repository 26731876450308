<template>
  <div id="app">
    <!-- <headers></headers> -->
    <div class="main">
      <!-- <carousel></carousel> -->
      <router-view></router-view>
    </div>
    <!-- <footers></footers> -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>

body{
  margin: 0;
}

</style>
